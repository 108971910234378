/**
 * Media query breakpoints
 */

@mixin breakpoint($point, $prefix: 'max') {

	@if $point == mobile {
		@media (#{$prefix}-width: $screen-xs-min) {
			@content;
		}
	}

	@else if $point == tablet {
		@media (#{$prefix}-width: $screen-sm-min) {
			@content;
		}
	}

	@else if $point == desktop {
		@media (#{$prefix}-width: $screen-md-min) {
			@content;
		}
	}

	@else if $point == mobileHeight {
		@media (#{$prefix}-height: 500px) {
			@content;
		}
	}

	@else if $point == print {
		@media only print  {
			@content;
		}
	}

	@else {
		@media (#{$prefix}-width : $point)  {
			@content;
		}
	}

}
