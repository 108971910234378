/**
 * Homepage Styles
 */

#homepage {

    #page-body {
        background: #fff;
    }

    .section-content,
    #page-content,
    #page-body {
        padding-bottom: 0;
    }

    #page-content {
        padding-top: 5px;
    }

/*
 * ================================
 * 	_About
 * ================================
 */

    #section-about {
        background: $brand-color-3;
        text-align: center;
        padding: 100px 0;
        color: #fff;

        .heading {
            h1,h2,h3,h4,h5,h6 {
                text-transform: uppercase;
                font-size: em(50px);
                margin: 0 0 20px 0;
                font-weight: bold;
                padding: 0;

                span {
                    color: $brand-color-2;
                    font-weight: 300;
                }
            }
        }

        .content {
            font-size: 1.3em;
            max-width: 700px;
            margin: 0 auto;

            p {
                line-height: 1.5em;
                padding: 0;
                margin: 0;
            }
        }
    }

/*
 * ================================
 * 	_Bibbeo Difference
 * ================================
 */

    #section-services {
        background: $brand-primary;
        position: relative;
        overflow: hidden;
        padding: 85px 0;
    }

/*
 * ================================
 * 	_Team
 * ================================
 */

    #section-about-text {
        background: $brand-color-1;
        padding: 100px 0;
        font-size: 1.3em;
        color: #fff;

        .post {
            position: relative;

            .content {
                text-align: center;
                max-width: 700px;
                margin: 0 auto;
            }

            p {
                line-height: 1.5em;
                padding: 0;
                margin: 0;
            }
        }
    }


/*
 * ================================
 * 	_Products
 * ================================
 */

    #section-products {
        border-top: 1px solid $brand-primary;
        background: $brand-primary;
        overflow: hidden;
        padding: 100px 0;

        @include breakpoint(mobile) {
            max-width: 500px;
            margin: 0 auto;
        }

        .section-heading {
            h1,h2,h3,h4,h5,h6 {
                text-transform: uppercase;
                font-size: em(50px);
                margin: 0 0 30px 0;
                text-align: center;
                font-weight: bold;
                padding: 0;

                span {
                    font-weight: 300;
                }
            }
        }

        .products-container {
            margin: 0 -20px;
            @include clearfix();
        }

        .link {
            margin: 40px 0 0 0;
            text-align: center;

            .btn {
                padding: 18px 80px;
                font-size: 1.2em;
            }
        }

        .post {
            padding: 0 15px;
            float: left;
            width: 33%;

            &:nth-child(3n+4) {
                clear: both;
            }

            @include breakpoint(tablet) {
                width: 50%;

                &:nth-child(3n+4) {
                    clear: none;
                }

                &:nth-child(2n+3) {
                    clear: both;
                }
            }

            @include breakpoint(mobile) {
                clear: none;
                width: 100%;
            }

            a {
                text-decoration: none;
                color: $text-color;
                overflow: hidden;
            }

            h1,h2,h3,h4,h5,h6 {
                font-size: 1em;
                padding: 0;
                margin: 0;
            }

            p {
                padding: 0;
                margin: 0;
            }

            .container {
                border:1px solid #e8e4e3;
                transition: all .3s ease;
                background: #fff;
                overflow: hidden;
                display: block;
                margin: 12px 0;
                padding: 12px;

                &:hover,
                &:focus {
                    box-shadow: rgba(0, 0, 0, 0.3) 0px 4px 44px 0px;
                }
            }

            .img-container {
                margin-bottom: 30px;
                text-align: center;
                overflow: hidden;
                height: 200px;
            }

            .heading {
                color: $brand-color-1;
                font-size: 1.3em;
            }

            .content {
                margin-top: 10px;
                font-size: .8em;
            }

            .price {
                color: $brand-color-1;
                margin-bottom: 10px;
                font-size: 1.3em;
            }

            .sale {
                font-size: .6em;
                color: #777;
            }

            .meta {
                font-weight: 300;
                margin-top: 10px;

                p {
                    display: inline-block;
                }
            }

            .date .icon {
                @include icon-font();
                @include icon($fa-var-clock-o);
            }

            .comments .icon {
                @include icon-font();
                @include icon($fa-var-comment-o);
            }

            .link {
                text-transform: uppercase;
                text-align: center;
                margin-top: 10px;
                font-size: .8em;

                a,
                input[type="submit"],
                button {
                    border-color: transparent;
                    white-space: normal;
                    border-radius: 0;
                    padding: 10px 0;

                    @extend .btn;
                    @extend .btn.btn-block;
                    @extend .btn.btn-primary;
                }

                .visible-mobile {
            		display: none;

            		@include breakpoint(tablet) {
            			display: block;
            		}
            	}

            	.hidden-mobile {
            		display: block;

            		@include breakpoint(tablet) {
            			display: none;
            		}
            	}
            }
        }
    }

}
