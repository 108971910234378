/**
 * Nav Styles
 */

/*
 * ================================
 * 	_Mobile nav
 * ================================
 */

    body.nav-open {
        overflow: hidden;
    }

    #mobile-nav {
        background: $background-color;
		top: $header-height-mobile;
		visibility: hidden;
		color: $text-color;
		max-width: 400px;
		overflow: hidden;
		position: fixed;
		font-size: 16px;
        display: none;
		z-index: 700;
		right: -70%;
		width: 70%;
		bottom: 0;

		@include transition(all .3s ease);

		@include breakpoint(tablet) {
            display: block;
		}

		body.nav-open & {
            visibility: hidden;
			display: block;
			right: 0;

			@include breakpoint(tablet) {
                visibility: visible;
			}
		}

		.container {
			min-width: 100%;
			max-width: 100%;
			width: 100%;
			padding: 0;
			margin: 0;
		}

		.container-wrap {
            border-left: 1px solid #eee;
			position: absolute;
			overflow: auto;
			width: 100%;
			bottom: 0;
            top: 0;
		}


		ul {
			list-style: none;
            clear: both;
            padding: 0;
			margin: 0;
		}

		ul + ul {
			border-top: 1px solid $brand-primary;
		}

		li {
			position: relative;
			padding: 0;
			margin: 0;
		}

		li a {
			border-top: 1px solid #ddd;
			font-family: $font-family-secondary;
			text-decoration: none;
			padding: 15px 25px;
			color: $text-color;
			font-weight: 400;
			display: block;

			@include transition(all .3s ease);

            &:focus,
			&:hover {
				background: $brand-primary;
			}
		}

        .favorites {
            display:none;
            &.exists {
                display:list-item;
            }
        }

		.quote {
			border-top: 1px solid #ddd;
			padding: 16px 0 0 0;
			margin: 0 0 16px 0;
			overflow: hidden;
		}

		.quote a {
			background: $brand-primary;
			text-transform: uppercase;
			text-decoration: none;
			letter-spacing: .08em;
			font-size: em(13px);
			text-align: center;
			line-height: 1.5em;
			margin: 0 auto;
			font-weight: bold;
			padding: 12px 15px;
			line-height: 12px;
			color: $gray-base;
			max-width: 200px;
			font-size: .8em;
			display: block;
			color: #fff;

			&:hover,
			&:focus {
				background-color: lighten($brand-primary,5%);
			}
		}

		li.dropdown.open > a.dropdown-toggle,
		li.dropdown > a.dropdown-toggle {
			padding-right: 50px;

			&:before {
				text-align: center;
				position: absolute;
				line-height:50px;
				font-size: 22px;
				display: block;
				width: 50px;
				right: 0;
				top: 0;
			}
		}

		li.dropdown.open {
            background: #fff;
		}

		li.dropdown.open > a.dropdown-toggle {
			 &:before {
				text-align: center;
				position: absolute;
				line-height:50px;
				 font-size: 22px;
				display: block;
				 width: 50px;
				right: 0;
				 top: 0;
			 }
		}

		li.dropdown.open .dropdown-menu {
		    background: #eee;
			display: block;
	    }

		ul ul,
		.dropdown-menu {
			border-bottom: 1px solid #eee;
			background: transparent;
			position: relative;
			font-size: 14px;
			display: none;
			bottom: auto;
			border: none;
			float: none;
			width: 100%;
			z-index: 0;
			top: auto;

			li {
				border: none;
			}

			li:after {
				display: none;
			}

			a {
				padding-left: 40px;
			}
		}
	}
