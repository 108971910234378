/**
 * Ecommerce Styles
 *
 */

#page-body {

    // Results count
    .woocommerce-result-count {
        margin: 30px auto 0 auto;
        text-align: center;
        line-height: 40px;
        padding: 0;
    }

    // Filtering
    .woocommerce-ordering {
        margin: 10px auto 0 auto;
        text-align: center;
        max-width: 25%;
    }

    // Pagination
    .woocommerce-pagination {
        text-align: center;
        clear: both;

        ul {
            list-style: none;
            display: inline-block;
            white-space: nowrap;
            clear: both;
            border-image-source: initial;
            border-image-slice: initial;
            border-image-width: initial;
            border-image-outset: initial;
            border-image-repeat: initial;
            padding: 0;
            border-width: 1px 0px 1px 1px;
            border-color: rgb(224, 218, 223) rgb(224, 218, 223) rgb(224, 218, 223);
            border-style: solid solid solid;
            border-right: 0px;
            margin: 1px;
            border-radius: 5px;
            overflow: hidden;
        }

        li {
            border-right: 1px solid rgb(224, 218, 223);
            overflow: hidden;
            display: inline;
            padding: 0px;
            margin: 0px;
            float: left;
        }

        span,
        a {
            text-decoration: none;
            line-height: 1em;
            font-weight: 400;
            font-size: 1em;
            min-width: 1em;
            display: block;
            padding: .7em;
            margin: 0px;
        }

        span {
            background: rgb(247, 246, 247);
            color: rgb(153, 136, 150);
        }
    }

    // Account page
    .woocommerce {
    	.addresses {
    		.title {
    			@include clearfix();

    			h3 {
    				float: left;
    			}

    			.edit {
    				float: right;
    			}
    		}
    	}

    	ol.commentlist.notes {
    		li.note {

    			p.meta {
    				font-weight: 700;
    				margin-bottom: 0;
    			}

    			.description {
    				p:last-child {
    					margin-bottom: 0;
    				}
    			}
    		}
    	}
    	ul.digital-downloads {
    		margin-left: 0;
    		padding-left: 0;

    		li {
    			list-style: none;
    			margin-left: 0;
    			padding-left: 0;

    			&:before {
    				content: "\e00a";
    			}

    			.count {
    				float: right;
    			}
    		}
    	}
    }

    // Info message
    .woocommerce-error,
    .woocommerce-message,
    .woocommerce-info {
        border-bottom :1px solid #e8e4e3;
        border-right :1px solid #e8e4e3;
        border-left :1px solid #e8e4e3;
        border-radius: 3px;
        background: #fff;
    }

    // Cart
    .woocommerce {
    	table.shop_table {
            background: #fff;
            overflow: hidden;

            a.remove {
                margin: 0 auto;
            }

            .product-remove,
    		.product-thumbnail {
                text-align: center;
                padding: 6px 2px;
    		}

            .cart_item {
                border-color: transparent;
            }

    		img {
    			width: 32px;
    			box-shadow: none;
    		}

            th {
                text-transform: uppercase;
                font-size: .8em;
            }

    		th,
    		td {
    			vertical-align: middle;
    		}

            p {
                padding: 0;
                margin: 0;
            }

    		td.actions {
    			.coupon {
    				.input-text {
    					float: left;
    					-webkit-box-sizing: border-box;
    					-moz-box-sizing: border-box;
    					box-sizing: border-box;
    					border: 1px solid darken( $brand-primary, 10% );
    					padding: 6px 6px 5px;
    					margin: 0 4px 0 0;
    					outline: 0;
    					line-height: 1;
                        min-width: 125px;
    				}
    			}
    		}

    		input {
    			margin: 0;
    			vertical-align: middle;
    			line-height: 1;
    		}
    	}

    	.wc-proceed-to-checkout {
    		@include clearfix;
    		padding: 1em 0;

    		a.checkout-button {
    			display: block;
    			text-align: center;
    			margin-bottom: 1em;
    			font-size: 1.25em;
    			padding: 1em;
    		}
    	}

    	.cart-collaterals {
    		.shipping_calculator {
    			.button {
    				width: 100%;
    				float: none;
    				display: block;
    			}

    			.shipping-calculator-button {
    				&:after {
    					content: "\e019";
    				}
    			}
    		}

    		.cart_totals {
    			p {
                    padding: 0;
                    margin: 0;

    				small {
    					color: $text-color;
    					font-size: 0.83em;
    				}
    			}

                .woocommerce-shipping-calculator {
                    margin-top: 20px;
                }

                .shipping-calculator-form {
                    padding: 10px 0;

                    p + p {
                        margin-top: 5px;
                    }
                }

    			table {
    				border-collapse: separate;
    				margin: 0 0 6px;
    				padding: 0;

    				tr:first-child {
    					th,
    					td {
    						border-top: 0;
    					}
    				}

    				th {
    					width: 40%;
    				}

    				td,
    				th {
    					vertical-align: top;
    					border-left: 0;
    					border-right: 0;
    					line-height: 1.5em;
    				}

    				small {
    					color: $text-color;
    				}

    				select {
    					width: 100%;
    				}
    			}

    			.discount td {
    				color: $text-color;
    			}

    			tr td,
    			tr th {
    				border-top: 1px solid #e8e4e3;
    			}
    		}

    		.cross-sells {
    			ul.products {
    				li.product {
    					margin-top: 0;
    				}
    			}
    		}
    	}
    }

    // Checkout
    .woocommerce,
    #add_payment_method {
        .woocommerce-thankyou-order-received {
            margin: 0 0 40px 0;
            font-size: 1.2em;
            padding: 0;
        }

        .customer_details,
        .order_details {
            thead th,
            thead .product-name {
                text-align: center;
            }

            thead th {
                text-align: center;
            }

            tbody th,
            tfoot th,
            tbody .product-name,
            tfoot .product-name {
                text-align: right;
            }
        }

        .checkout h3 {
            font-size: 1.5em;
        }

        .checkout_coupon {
            p {
                padding: 0;
                margin: 0;
            }
        }

    	.checkout {
    		.col-2 {
    			h3#ship-to-different-address {
    				float: left;
    				clear: none;
    			}

    			.notes {
    				clear: left;
    			}

    			.form-row-first {
    				clear: left;
    			}
    		}

    		.create-account small {
    			font-size: 11px;
    			color: $text-color;
    			font-weight: normal;
    		}

    		div.shipping-address {
    			padding: 0;
    			clear: left;
    			width: 100%;
    		}

    		.shipping_address {
    			clear: both;
    		}
    	}

    	#payment {
    		background: #fff;
    		border-radius: 5px;

    		ul.payment_methods {
    			@include clearfix();
    			text-align: left;
    			padding: 1em;
    			border-bottom: 1px solid darken( $text-color, 10% );
    			margin: 0;
    			list-style: none outside;

    			li {
    				line-height: 2;
    				text-align: left;
    				margin: 0;
    				font-weight: normal;
    				@include clearfix;

    				input {
    					margin: 0 1em 0 0;
    				}

    				img {
    					vertical-align: middle;
    					margin: -2px 0 0 .5em;
    					padding: 0;
    					position: relative;
    					box-shadow: none;
    				}

    				img + img {
    					margin-left: 2px;
    				}
    			}
    		}

    		div.form-row {
    			padding: 1em;
    		}

    		div.payment_box {
    			position: relative;
    			box-sizing: border-box;
    			width: 100%;
    			padding: 1em;
    			margin: 1em 0 1em 0;
    			font-size: 0.92em;
    			border-radius: 2px;
    			line-height: 1.5;
    			background-color: darken( #fff, 5% );
    			color: $text-color;

    			input.input-text, textarea {
    				border-color: darken( $text-color, 15% );
    				border-top-color: darken( $text-color, 20% );
    			}

    			::-webkit-input-placeholder {
    				color: darken( $text-color, 20% );
    			}

    			:-moz-placeholder {
    				color: darken( $text-color, 20% );
    			}

    			:-ms-input-placeholder {
    				color: darken( $text-color, 20% );
    			}

    			.wc-credit-card-form-card-number,
    			.wc-credit-card-form-card-expiry,
    			.wc-credit-card-form-card-cvc {
    				font-size: 1.5em;
    				padding: 8px;
    				background-repeat: no-repeat;
    				background-position: right;

    				&.visa {
    					background-image: url(#{$image-path}/icons/credit-cards/visa.png);
    				}

    				&.mastercard {
    					background-image: url(#{$image-path}/icons/credit-cards/mastercard.png);
    				}

    				&.laser {
    					background-image: url(#{$image-path}/icons/credit-cards/laser.png);
    				}

    				&.dinersclub {
    					background-image: url(#{$image-path}/icons/credit-cards/diners.png);
    				}

    				&.maestro {
    					background-image: url(#{$image-path}/icons/credit-cards/maestro.png);
    				}

    				&.jcb {
    					background-image: url(#{$image-path}/icons/credit-cards/jcb.png);
    				}

    				&.amex {
    					background-image: url(#{$image-path}/icons/credit-cards/amex.png);
    				}
    				&.discover {
    					background-image: url(#{$image-path}/icons/credit-cards/discover.png);
    				}
    			}

    			span.help {
    				font-size: .857em;
    				color: $text-color;
    				font-weight: normal;
    			}

    			.form-row {
    				margin: 0 0 1em;
    			}

    			p:last-child {
    				margin-bottom: 0;
    			}

    			&:before {
    				content: "";
    				display: block;
    				border: 1em solid darken( #fff, 5% );
    				border-right-color: transparent;
    				border-left-color: transparent;
    				border-top-color: transparent;
    				position: absolute;
    				top: -.75em;
    				left: 0;
    				margin: -1em 0 0 2em;
    			}
    		}

    		.payment_method_paypal {
    			.about_paypal {
    				float: right;
    				line-height: 52px;
    				font-size: 0.83em;
    			}

    			img {
    				max-height: 52px;
    				vertical-align: middle;
    			}
    		}
    	}
    }

    #customer_details {
        margin: 0 0 40px 0;
    }

    // Password strength
    .woocommerce-password-strength {
    	text-align: center;
    	font-weight: 600;
    	padding: 3px .5em;
    	font-size: 1em;

    	&.strong {
    		background-color: #c1e1b9;
    		border-color: #83c373;
    	}

    	&.short {
    		background-color: #f1adad;
    		border-color: #e35b5b;
    	}

    	&.bad {
    		background-color: #fbc5a9;
    		border-color: #f78b53;
    	}

    	&.good {
    		background-color: #ffe399;
    		border-color: #ffc733;
    	}
    }

    .woocommerce-password-hint {
    	margin: .5em 0 0 0;
    	display: block;
    }

    // Related products
    .related {
        margin: 100px 0 0 0;

        > h2 {
            text-overflow: ellipsis;
            color: $brand-color-1;
            white-space: nowrap;
            text-align: center;
            overflow: hidden;
            font-size: 1.8em;
            padding: 0;
            margin: 0;
        }
    }

    // Products div
    div.products {
        @include clearfix();
    }

    // Products Archive
    ul.products {
        margin: 40px -20px 0 -20px;
        list-style: none;
        float: left;
        clear: both;
        padding: 0;

        @include clearfix();

        @include breakpoint(mobile) {
            margin: 40px 0 0 0;
            width: 100%;
            float: none;
        }

        li {
            position: relative;
            margin: 0 0 30px 0;
            padding: 0 20px;
            float: left;
            width: 33%;

            &:nth-child(3n+4) {
                clear: both;
            }

            @include breakpoint(tablet) {
                width: 50%;

                &:nth-child(3n+4) {
                    clear: none;
                }

                &:nth-child(2n+3) {
                    clear: both;
                }
            }

            @include breakpoint(mobile) {
                clear: none;
                width: 100%;
            }
        }

        a {
            color: $brand-color-1;
            display: block;
        }

        img {
            padding: 0;
            margin: 0;
        }

        p {
            padding: 0;
            margin: 0;
        }

        h1,h2,h3,h4,h5,h6 {
            text-overflow: ellipsis;
            color: $brand-color-1;
            white-space: nowrap;
            overflow: hidden;
            font-size: 1.3em;
            padding: 0;
            margin: 0;
        }

        .container {
            transition: all .3s ease;
            border:1px solid #e8e4e3;
            background: #fff;
            overflow: hidden;
            padding: 20px;

            &:hover,
            &:focus {
                box-shadow: rgba(0, 0, 0, 0.3) 0px 4px 44px 0px;
            }
        }

        .img-container {
            margin-bottom: 20px;
            text-align: center;
            overflow: hidden;
            height: 200px;

            &.sm {
                height: 150px;
            }
        }

        .content {
            margin-top: 10px;
            font-size: .8em;
        }

        .added_to_cart {
            margin: 10px 0 0 0;
            text-align: center;
            display: block;
        }

        .button {
            background-color: lighten($brand-color-1, 15%);
            text-transform: uppercase;
            text-decoration: none;
            margin: 10px 0 0 0;
            padding: 12px 20px;
            text-align: center;
            display: block;
            color: #fff;
        }

        .price {
            margin-bottom: 10px;
            padding: 2px 0 0 0;
            display: block;

            del {
                font-size: .9em;
                display: block;
                color: #777;
            }

            ins {
                background: transparent;
                color: $brand-color-1;
                font-size: 1.3em;
                display: block;
            }
        }

        .onsale {
            position: absolute;
            min-height: 35px;
            min-width: 55px;
            font-size: 15px;
            font-weight: 400;
            padding: 0;
            text-transform: uppercase;
            text-align: center;
            line-height: 35px;
            top: 20px;
            left: 20px;
            right: auto;
            margin: 0;
            color: #fff;
            text-shadow: none;
            background: #fd6408;
            box-shadow: none;
            border: none;
            border-radius: 0;
        }
    }

    // Product Single
    div.product {
        .images {
            margin-bottom: 2em;
            text-align: center;
            overflow: hidden;
            padding: 5px;
            float: left;
            width: 35%;

            img {
                max-width: auto;
                height: auto;
                width: auto;
            }
            a {
                border: 1px solid rgb(214, 214, 214);
                border-radius: 2px;
                background: #fff;
                display: block;
            }

			a.last {
				margin-right: 0;
			}

            @include breakpoint(tablet) {
                max-width: 400px;
                margin: 0 auto;
                float: none;
                width: 100%;
            }
        }

        .thumbnails {
            display: block;
            text-align: center;
            padding-top: 20px;
            font-size: 0px;
            line-height: 0;
            margin: 0px;

            a {
                display: inline-block;
                overflow: hidden;
                background: #fff;
                margin: 0px 4px;
                max-width: 60px;
                height: 60px;
                padding: 5px;
                width: auto;
                float: none;
            }
        }

        .summary {
            padding: 20px 0 0 0;
            position: relative;
            float: right;
            width: 60%;

            .link {
                margin-top: 40px;
            }

            @include breakpoint(tablet) {
                margin-top: 40px;
                float: none;
                width: 100%;
            }
        }

        .price {
            margin-bottom: 10px;
            padding: 2px 0 0 0;
            display: block;

            del {
                font-size: .9em;
                display: block;
                color: #777;
            }

            ins {
                background: transparent;
                color: $brand-color-1;
                font-size: 1.3em;
                display: block;
            }
        }

        > .onsale {
            position: absolute;
            min-height: 35px;
            min-width: 55px;
            font-size: 15px;
            font-weight: 400;
            padding: 0;
            text-transform: uppercase;
            text-align: center;
            line-height: 35px;
            top: 65px;
            right: 0;
            left: auto;
            margin: 0;
            color: #fff;
            text-shadow: none;
            background: #fd6408;
            box-shadow: none;
            border: none;
            border-radius: 0;
        }

        .variations {
            &,th,td,tr {
                border: none;
            }
        }

        .cart {
            margin: 0 0 40px 0;
        }

        .quantity {
            margin: 0 4px 0 0;
            float: left;
            width: 60px;

            input {
                border-radius: 0;
                height: 44px;
            }
        }

        .product_title {
            padding-right: 60px;
            font-size: 1.8em;
        }

        .woocommerce-tabs {
            padding: 40px 0 0 0;
            clear: both;

            .tabs {
                position: relative;
                padding: 0px 0px 0px 8px;
                margin: 0px;
                background: none;
                border-radius: 0px;
                list-style: none;
            }

            .tabs li {
                display: inline-block;
                border-bottom: none;
                background: none;
                margin: 0;
                padding: 0;
                top: 0px;

                &.active a {
                    border-bottom: 1px solid #fff;
                    text-decoration: none;
                    background: #fff;
                    cursor: default;
                    display: block;
                    bottom: -1px;
                }
            }

            .tabs a {
                text-shadow: none;
                color: inherit;
                font-weight: bold;
                text-decoration: none;
                display: block;
                border-image-source: initial;
                border-image-slice: initial;
                border-image-width: initial;
                border-image-outset: initial;
                border-image-repeat: initial;
                padding: 12px 15px;
                background: rgb(233, 234, 235);
                border-radius: 0px;
                border-width: 1px;
                border-style: solid;
                border-color: rgb(214, 214, 214);
                margin: 0px 0px -1px 0;
                transition: all 200ms linear;
            }

            .panel {
                box-shadow: rgba(0, 0, 0, 0.0470588) 0px 2px 3px;
                border: 1px solid rgb(214, 214, 214);
                background: #fff;
                padding: 25px;
            }

            .panel h2 {
                font-size: 1.5em;
            }
        }
    }

    // Review
	p.stars {
		a {
			position: relative;
			height: 1em;
			width: 1em;
			text-indent: -999em;
			display: inline-block;
			text-decoration: none;

			&:before {
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				width: 1em;
				height: 1em;
				line-height: 1;
				font-family: "WooCommerce";
				content: "\e021";
				text-indent: 0;
			}

			&:hover {
				~ a:before {
					content: "\e021";
				}
			}
		}

		&:hover {
			a {
				&:before {
					content: "\e020";
				}
			}
		}

		&.selected {
			a.active {
				&:before {
					content: "\e020";
				}

				~ a:before {
					content: "\e021";
				}
			}

			a:not(.active) {
				&:before {
					content: "\e020";
				}
			}
		}
    }
    
    // Products page and gallery
    .single-product {
        div.product {
            @include clearfix;
            position: relative;
            overflow: hidden;
    
            .woocommerce-product-gallery .flex-viewport {
                margin-bottom: 1.618em;
            }

            .images,
            .summary,
            .woocommerce-product-gallery {
                margin-bottom: ms(5);
                margin-top: 0;
            }

            .woocommerce-product-gallery {
                position: relative;

                .zoomImg {
                    background-color: #fff;
                }

                .woocommerce-product-gallery__trigger {
                    position: absolute;
                    top: ms(-1);
                    right: ms(-1);
                    display: block;
                    height: 2em;
                    width: 2em;
                    border-radius: 3px;
                    z-index: 99;
                    text-align: center;
                }

                img {
                    margin: 0;
                }

                .flex-viewport {
                    margin-bottom: ms(3);
                }

                .flex-control-thumbs {
                    @include clearfix;
                    margin: 0;
                    padding: 0;

                    li {
                        list-style: none;
                        margin-bottom: ms(3);
                        cursor: pointer;

                        img {
                            opacity: 0.5;
                            transition: all, ease, 0.2s;

                            &.flex-active {
                                opacity: 1;
                            }
                        }

                        &:hover {
                            img {
                                opacity: 1;
                            }
                        }
                    }
                }

                &.woocommerce-product-gallery--columns-2 {
                    .flex-control-thumbs {
                        li {
                            margin-right: 14.2857142857%;
                            width: 14.2857142857%;
                            float: left;

                            &:nth-child( 2n ) {
                                margin-right: 0;
                            }

                            &:nth-child( 2n+1 ) {
                                clear: both;
                            }
                        }
                    }
                }

                &.woocommerce-product-gallery--columns-3 {
                    .flex-control-thumbs {
                        li {
                            margin-right: 14.2857142857%;
                            width: 14.2857142857%;
                            float: left;
                            
                            &:nth-child( 3n ) {
                                margin-right: 0;
                            }

                            &:nth-child( 3n+1 ) {
                                clear: both;
                            }
                        }
                    }
                }

                &.woocommerce-product-gallery--columns-4 {
                    .flex-control-thumbs {
                        li {
                            margin-right: 14.2857142857%;
                            width: 14.2857142857%;
                            float: left;

                            &:nth-child( 4n ) {
                                margin-right: 0;
                            }

                            &:nth-child( 4n+1 ) {
                                clear: both;
                            }
                        }
                    }
                }

                &.woocommerce-product-gallery--columns-5 {
                    .flex-control-thumbs {
                        li {
                            margin-right: 14.2857142857%;
                            width: 14.2857142857%;
                            float: left;

                            &:nth-child( 5n ) {
                                margin-right: 0;
                            }

                            &:nth-child( 5n+1 ) {
                                clear: both;
                            }
                        }
                    }
                }
            }

            .images {
                .woocommerce-main-image {
                    margin-bottom: ms(3);
                    display: block;
                }

                .thumbnails {
                    a.zoom {
                        display: block;
                        width: 22.05%;
                        margin-right: 3.8%;
                        float: left;
                        margin-bottom: 1em;

                        &.last {
                            margin-right: 0;
                        }

                        &.first {
                            clear: both;
                        }
                    }
                }
            }

            form.cart {
                @include clearfix;
                margin-bottom: ms(3);
                padding: 1em 0;

                .quantity {
                    float: left;
                    margin-right: ms(-1);
                }

                table.woocommerce-grouped-product-list {
                    .woocommerce-grouped-product-list-item__quantity {
                        float: none;
                        margin-right: 0;

                        .quantity {
                            margin-right: 0;
                        }
                    }
                }
            }

            p.price {
                font-size: ms(2);
                margin: ms(2) 0;
            }

            table.variations {
                margin: 0;

                th,
                td {
                    display: list-item;
                    padding: 0;
                    list-style: none;
                    background-color: transparent;
                }

                .value {
                    margin-bottom: 1em;
                }
            }

            .single_variation {
                .price {
                    margin-bottom: 1em;
                    display: block;
                }
            }

            .variations_button {
                @include clearfix;
                padding-top: 1em;
            }

            .woocommerce-product-rating {
                margin-bottom: ms(3);
                @include clearfix;
                margin-top: - ms(-1);

                a {
                    text-decoration: underline;
                }

                .star-rating {
                    float: left;
                    margin-right: ms(-2);
                }
            }

            .product_meta {
                font-size: ms(-1);
                padding-top: 1em;
                border-top: 1px solid $brand-border;

                .sku_wrapper,
                .posted_in,
                .tagged_as {
                    display: block;

                    &:last-child {
                        border-bottom: 0;
                    }
                }

                a {
                    text-decoration: underline;
                }
            }
        }
    }

    // Stock
    .stock {
        &:empty::before {
            display: none;
        }

        &.in-stock {
            color: $brand-success;
        }

        &.out-of-stock {
            color: $brand-danger;
        }
    }

    // variations
    a.reset_variations {
        display: inline-block;
        margin-left: 1em;
    }


}