/**
 * Variables
 */

/*
 * ================================
 *	_Paths
 * ================================
 */

	$font-path: 	"../fonts";
	$vendor-path: 	"../vendor";
	$image-path: 	"../images";

/*
 * ================================
 *	_Sizes
 * ================================
 */

	// Screen widths
	$screen-xs-min: 480px;
	$screen-sm-min: 768px;
	$screen-md-min: 992px;
	$screen-lg-min: 1200px;
	$screen-min: 290px;
	$screen-max: 900px;

	// Layout sizes
	$header-height: 104px;
	$header-height-mobile: 65px;
	$sidebar-width: 30%;
	$content-width: 70%;
	$content-height-min: 300px;
	$breadcrumbs-height: 26px;

	// Padding
	$padding-base-vertical: 8px;
	$padding-base-horizontal: 8px;

/*
 * ================================
 *	_Colors
 * ================================
 */

	$brand-primary		: #f4f5f7;
	$brand-secondary	: #ddd;
	$brand-color-1		: #0e3d72;
	$brand-color-2		: #ff7903;
	$brand-color-3		: #353535;

	$brand-default		: $brand-color-1;
	$brand-success		: #70b333;
	$brand-info			: #5bc0de;
	$brand-warning		: #f0ad4e;
	$brand-danger		: #a50303;
	$brand-border		: #f4efef;

	$gray-base			: #000;
	$gray-darker		: lighten($gray-base, 13.5%); // #222
	$gray-dark			: lighten($gray-base, 20%);   // #333
	$gray				: lighten($gray-base, 33.5%); // #555
	$gray-light			: lighten($gray-base, 46.7%); // #777
	$gray-lighter		: lighten($gray-base, 93.5%); // #eee

	$background-color	: #fff;
	$header-color		: #fff;
	$footer-color		: #fff;

	// Text
	$text-color			: $gray-dark;
	$text-color-dark	: $gray-dark;
	$link-color			: $brand-color-1;
	$link-hover-color	: $brand-color-2;

	$header-text-color	: $gray-dark;
	$footer-text-color	: $gray-dark;
	$footer-link-color	: $gray-dark;
	$footer-link-hover-color: $gray-dark;

/*
 * ================================
 *	_Forms
 * ================================
 */

	$input-bg:				#fefefe;
	$input-bg-active:		darken($input-bg, 2%);
	$input-bg-disabled:		$gray-lighter;

	$input-error: 			#a50303;
	$input-color:			#4d4d4d;
	$input-border:			#ccc;
	$input-border-active:	#337ae5;
	$input-border-radius:	3px;
	$input-height-base: 	15px;
	$input-color-placeholder: $gray-light;

/*
 * ================================
 *	_Fonts
 * ================================
 */

	// Sizes
	$font-size-base: 16px;
	$line-height-base: 22px;

	// Fonts
	$font-family-sans-serif		: "Lato", "Helvetica Neue", helvetica, arial, sans-serif;
	$font-family-serif			: Georgia, "Times New Roman", Times, serif;
	$font-family-monospace		: Menlo, Monaco, Consolas, "Courier New", monospace;
	$font-family-base			: $font-family-sans-serif;
	$font-family-secondary		: "Roboto", $font-family-sans-serif;

/*
 * ================================
 *	_Other
 * ================================
 */

 	$fa-font-path: "#{$vendor-path}/font-awesome/fonts/";
	$fa-css-prefix: 'icon';
	$iconCssPrefix: 'icon';

