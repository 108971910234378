/**
 * Banner Styles
 *
 */

#page-body .banner {

	position: relative;
	overflow: hidden;
	padding: 0;

	&.slider {
		height: 600px;
		padding: 0;

		@include breakpoint(tablet) {
			height: 400px;
		}
	}

	&.navigation {
		font-size: 1.5em;
		padding: 20px 0;

		li,
		h1,h2,h3,h4,h5,h6 {
			font-size: 1.2em;
			font-family: $font-family-secondary;
		}

		ul {
			overflow: hidden;
			list-style: none;
			padding: 5px 0;
			float: left;
			width: 100%;
			margin: 0;
		}

		li {
			float: left;
			display: block;
			margin: 0;
			padding: 0;
			line-height: 1.2em;
		}

		li + li {
			&:before {
				padding: 0 5px;
				content: '|';
				line-height: 1em;
				font-size: .9em;
				top: -4px;
				position: relative;
			}
		}

		li a {
			color: $text-color;
		}

		li.current_page_item a,
		li span {
			color: $link-color;
		}
	}

	h1,h2,h3,h4,h5,h6 {
		margin: 0;
		padding: 0;
		line-height: 40px;
		font-weight: 400;
	}

	h1,h2,h3,h4,h5,h6 {
		text-overflow: ellipsis;
		font-size: 1.6em;
		overflow: hidden;
	}

	p {
		overflow: hidden;
		font-size: .7em;
		padding: 0;
		margin: 0;
	}

	.inside {
		max-width: 1100px;
		height: 100%;
	}

	.slide {
		position: relative;
		overflow: hidden;
		height: 600px;
		width: 100%;

		@include breakpoint(tablet) {
			height: 400px;
		}

		.img-container {
			background-repeat: no-repeat;
			background-position: 50% 50%;
			background-size: cover;
			position: absolute;
			width: 100%;
			height: 100%;
			z-index: 1;
			top: 0;
			left: 0;
		}

		.img-foreground-container {
			background-repeat: no-repeat;
			background-position: 50% 50%;
			background-size: contain;
			position: absolute;
			height: 80%;
			bottom: 10%;
			width: 40%;
			z-index: 1;
			right: 0;

			@include breakpoint(tablet) {
				display: none;
			}
		}

		.content {
			font-family: $font-family-secondary;
			transform: translateY(-50%);
			position: absolute;
			padding: 0 40px;
			color: #fdfdfd;
			width: 100%;
			z-index: 2;
			left: 0;
			top: 50%;

			@include breakpoint(tablet) {
				padding: 0;
			}
		}

		.content .inside {
			max-width: 1200px;
			overflow: hidden;
			height: 100%;
		}

		.content .container {
			height: 100%;
			width: 100%;
		}

		.content .container-inside {
			width: 60%;

			@include breakpoint(tablet) {
				width: 100%;
			}
		}

		h1,h2,h3,h4,h5,h6 {
			text-shadow: 1px 1px 0 rgba(0,0,0,.2);
			text-transform: uppercase;
			padding: 0 0 20px 0;
			line-height: .9em;
			font-weight: 700;
			font-size: 80px;
			margin: 0;

			@include breakpoint(tablet) {
				text-align: center;
				font-size: 50px;
			}
		}

		p {
			text-shadow: 1px 1px 0 rgba(0,0,0,.2);
			line-height: 1.2em;
			padding: 5px 0 0 0;
			margin: 5px 0 0 0;
			font-weight: 400;
			font-size: 38px;

			@include breakpoint(tablet) {
				text-align: center;
				font-size: 28px;
			}
		}
	}

	.slide-nav {
		@include breakpoint(tablet) {
			display: none;
		}

		.next,
		.prev {
			position: absolute;
			margin-top: -20px;
			font-size: 32px;
			cursor: pointer;
			padding: 20px;
			z-index: 100;
			color: #fff;
			top: 50%;
		}

		.prev {
			right: auto;
			left: 0;
			@include icon-font();
			@include icon($fa-var-chevron-left);
		}

		.next {
			left: auto;
			right: 0;
			@include icon-font();
			@include icon($fa-var-chevron-right);
		}
	}

} // #page-body
