/**
 * Blog Styles
 */

/*
 * ================================
 * 	_BlogPost
 * ================================
 */

	#page-body .post-article {
		position: relative;
		overflow: hidden;
		float: left;
		width: 100%;
		clear: both;

		& + .post-article {
			border-top: 1px solid $brand-border;
			padding: 30px 0 0 0;
			margin: 30px 0 0 0;
		}

		// Heading
		.post-heading {
			padding: 0 0 20px 0;
			position: relative;
			overflow: hidden;
			display: block;
		}

		.post-heading h1 {
			padding: 0 0 10px 0;
			font-size: 1.5em;
			border: none;
			margin: 0;
		}

		.post-heading a {
			text-decoration: none;
			color: $text-color;
		}

		.post-heading p {
			font-family: $font-family-secondary;
			line-height: 1.2em;
			font-size: .9em;
			padding: 0;
			margin: 0;
		}

		// Date
		.post-heading .date {
			font-size: .9em;
			display: block;
		}

		.post-content .date {
			line-height: 1.2em;
			float: left;
		}

		.post-heading .tags {
			font-size: .9em;
			padding: 0;
			width: 50%;
		}

		// Post footer
		.post-footer {
			padding: 15px 0 0 0;
		}

		// More link
		.link {
			position: relative;
			padding: 20px 0 0 0;
			font-size: .8em;
			float: right;
			margin: 0;
		}

		.link a {
			padding: 8px 15px;
		}

		// Content
		.post-excerpt,
		.post-content {
			line-height: 1.2em;
			padding: 0;
			margin: 0;
		}

		.post-excerpt + .post-excerpt,
		.post-content + .post-content {
			margin-top: 10px;
		}

		.post-content p:last-child,
		.post-content ul:last-child {
			padding-bottom: 0;
			margin-bottom: 0;
		}

		.post-excerpt p,
		.post-excerpt ul {
			padding: 0;
			margin: 0;
		}

		.post-preview {
			margin: 0 0 25px 0;
			overflow: hidden;

			a {
				max-height: 200px;
				display: block;
			}
		}
	}

/*
 * ================================
 * 	_BlogNavigation
 * ================================
 */

 	// Single nav
	#page-body .nav-single {
		overflow: hidden;
		padding: 30px 0;
		clear: both;

		a {
			@extend .btn !optional;
			padding: 0 5px;
		}

		.btn {
			padding: 8px 10px;
		}
	}

	// Pagination
	#page-body .pagination {
		padding: 20px 0 3px 0;
		margin: 30px 0 0 0;
		text-align: center;
		overflow: hidden;
		line-height: 1em;
		display: block;
		float: left;
		width: 100%;
		clear: both;

		a,
		span {
			background-color: transparent;
			border: 2px solid $brand-default;
			background-repeat: no-repeat;
			vertical-align: middle;
			display: inline-block;
			text-decoration: none;
			color: $brand-default;
			font-weight: normal;
			white-space: nowrap;
			border-radius: 4px;
			padding: 10px 10px;
			position:relative;
			margin-bottom: 0;
			cursor: pointer;
			margin: 0 2px;
			outline:none;

			&:hover,
			&:focus {
				border-color: lighten($brand-default, 2.5%);
				text-decoration: none;
				color: $brand-color-1;
			}
		}

		span {
			background-color: #ddd;
			border-color: #4e4e4e;
			color: #4e4e4e;
		}
	}

/*
 * ================================
 * 	_Comments
 * ================================
 */

	#page-body #comments {
		padding: 10px 0 0 0;
		margin: 15px 0 0 0;

		ol,
		ul {
			list-style: none;
			padding: 0;
			margin: 0;
		}

		.comment {
			border: 1px solid #e8e4e3;
			background: #f7f7f7;
			position: relative;
			margin: 0 0 15px 0;
			padding: 10px;
			@include box-shadow(0 0 6px -2px rgba(0,0,0,0.4));
		}

		.comment .comment-inside {
			border: 1px solid #e8e4e3;
			padding: 25px 20px;
			background: #fff;
		}

		.comment-notes {
			margin: 0 0 15px 0;
			font-style: italic;
			font-size: 16px;
			color: #777;
		}

		.children .comment.bypostauthor,
		.comment.bypostauthor,
		.comment.bypostauthor .comment-inside {
			background: #e7f1f3;
		}

		.children {
			margin: 10px 0 0 15px;
			position: relative;
			list-style: none;
			right: -30px;
		}

		.children .comment {
			background: #fff;
			@include box-shadow(0 0 12px -4px rgba(0,0,0,0.6));
		}

		.comment-meta {
			margin-bottom: 5px;
		}

		.comment-meta a {
			color: $text-color;
		}

		.comment-meta img {
			padding: 0 10px 0 0;
			float: left;
		}

		.comment-meta-intro {
			font-family: $font-family-secondary;
			padding: 2px 0 0 0;
			font-size: 22px;
		}

		.comment-meta-date {
			font-size: 14px;
		}

		.comment .reply {
			position: absolute;
			font-size: 12px;
			margin: 15px;
			padding: 0;
			right: 0;
			top: 0;
		}

		.comments-title  {
			font-family: $font-family-secondary;
			padding: 13px 0 25px 0;
			line-height: 1.1em;
			font-weight: bold;
			font-size: 27px;
			border: none;
		}

		#reply-title {
			font-family: $font-family-base;
			line-height: 1.1em;
			padding: 20px 0;
			font-size: 22px;
			border: none;
			margin: 0;
		}

		.comment-nav-below {
			border-top: 1px solid $text-color-dark;
			text-align: center;
			margin: 15px 0 0 0;
			padding: 15px 0;
		}

		.comment-nav-below a {
			@extend .btn !optional;
		}

		.comment-meta-moderation {
			padding: 10px 0;
		}

		.comment-awaiting-moderation {
			font-size: 12px;
			display: block;
		}
	}

