/**
 * Button Styles
 */

input.btn,
a.btn,
.btn {

	background-color: transparent;
	border: 2px solid $brand-default;
	background-repeat: no-repeat;
	vertical-align: middle;
	display: inline-block;
	text-decoration: none;
	color: $brand-default;
	font-weight: normal;
	white-space: nowrap;
	border-radius: 4px;
	padding: 12px 25px;
	position:relative;
	margin-bottom: 0;
	cursor: pointer;
	outline:none;

	&:hover,
	&:focus {
		border-color: lighten($brand-default, 2.5%);
		text-decoration: none;
		color: $brand-color-1;
	}

	&:active,
	&.active {
		outline: 0;
	}

	&.disabled,
	&[disabled] {
		text-shadow:0 1px 0 #f7f6f3;
		background-color: #bab9b9;
		border-color:#a8a8a8;
		font-weight:bold;
		box-shadow: none;
		cursor: default;
		top:0;
	}

	&.btn-sm {
		padding: 6px 12px;
		line-height: 1em;
		font-size: .8em;
	}

	&.btn-lg {
		padding: 20px 30px;
		line-height: 1em;
		font-size: 1em;
	}

	&.btn-block {
		display: block;
		width: 100%;

		& + .btn-block {
			margin-top: 5px;
		}
	}

	// Alternative btns
	&.btn-default {
		background-color: $brand-default;
		color: #fff;

		&:hover,
		&:active{
			background-color: lighten($brand-default, 2%);
		}
	}

	&.btn-primary {
		background-color: lighten($brand-color-1, 15%);
		border-color: transparent;
		color: #fff;

		&:hover,
		&:active{
			background-color: lighten($brand-color-1, 20%);
			color: #fff;
		}
	}

	&.btn-warning {
		background-color:$brand-warning;
		border-color: transparent;
		color: #525252;

		&:hover,
		&:active{
			background-color: lighten($brand-warning, 2%);
			color: #fff;
		}
	}

	&.btn-cancel,
	&.btn-danger {
		background-color: $brand-danger;
		border-color: transparent;
		color: #525252;

		&:hover,
		&:active{
			background-color: lighten($brand-danger, 2%);
			color: #fff;
		}
	}

	&.btn-save,
	&.btn-success {
		background-color: $brand-success;
		border-color: transparent;
		color: #525252;

		&:hover,
		&:active{
			background-color: lighten($brand-success, 2%);
			color: #fff;
		}
	}

	&.btn-info {
		background-color: $brand-info;
		border-color: transparent;
		color: #fff;

		&:hover,
		&:active{
			background-color: lighten($brand-info, 2%);
			color: #fff;
		}
	}

	&.btn-text {
		background: transparent;
		box-shadow: none;
		padding-right: 0;
		padding-left: 0;
		border: none;

		&:active {
			bottom: auto;
		}

		&:focus,
		&:hover {
			text-decoration: underline;
		}
	}

}