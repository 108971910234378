/**
 * Body Styles
 */

#page-body {

	padding-top: $header-height + 37px;
	// background: $brand-primary;
	position: relative;
	font-size: 100%;
	clear: both;

	body#shop & {
		background: $brand-primary;
	}

	@include breakpoint(tablet) {
		padding-top: $header-height-mobile;
	}

	.ge-hide-field {
		display: none;
	}
/*
 * ================================
 * 	_PageContent
 * ================================
 */

 	#page-content {
 		padding: 0 0 60px 0;
		overflow: hidden;

		@include clearfix();

		&.full .section-content {
			margin-right: auto;
			margin-left: auto;
			max-width: 600px;
			float: none;
			width: 100%;
		}

 		&.wide .section-content {
			float: none;
 			width: 100%;
 		}

		&.full .section-sidebar,
 		&.wide .section-sidebar {
 			display: none;
 		}

		&.full .content-inside,
 		&.wide .content-inside {
			padding-left: 0;
 			padding-right: 0;
 		}
 	}

 	.section-content {
		min-height: $content-height-min;
		width: $content-width;
		float: right;

		@include breakpoint(tablet) {
			width: 100%;
			float: none;
		}

		body#search & {
			margin: 0 auto;
			float: none;
		}

		p,
		ul,
		ol {
			line-height: 1.5em;
		}

		.heading {
			color: $brand-color-1;
			margin: 0 0 40px 0;

			h1,h2,h3,h4,h5,h6 {
				font-size: em(32px);
				padding: 0;
				margin: 0;
			}
		}

		.content-inside {
			padding-top: 50px;
		}

		.featured-image {
			margin: 0 -50px 10px 10px;
			max-width: 60%;
			float: right;
		}

		.search-form {
			border: 1px solid #ddd;
			margin: 0 0 80px 0;
			background: #fafafa;
			overflow: hidden;
			padding: 20px;
			clear: both;

			.search-submit {
				text-align: center;
			}
		}

		.link {
			margin: 40px 0 0 0;
			clear: both;
		}

		.error-content {
			margin: 0 auto 40px auto;
			max-width: 500px;
		}
 	}

	.section-sidebar {
		width: $sidebar-width;
		padding: 50px 0 0 0;
		overflow: hidden;
	    margin-top: 0;
	    float: left;

		@include breakpoint(tablet) {
			margin-top: 40px;
			width: 100%;
			float: none;
		}

		body#search & {
			display: none;
		}

		.sidebar-inside {
			padding: 0 40px 0 0;

			@include breakpoint(tablet) {
				padding: 0;
			}
		}

		.sidebar-inside > div + div {
			position: relative;
			margin-top: 70px;
		}

		.widget-heading,
		.heading {
			font-family: $font-family-secondary;
			text-transform: uppercase;
			margin: 0 0 30px 0;
			border: none;

			h1,h3,h2,h3,h4,h6 {
				color: $text-color;
				font-weight: 400;
				font-size: 26px;
				border: none;
				padding: 0;
				margin: 0;
			}
		}

		.widget_nav_menu,
		.navigation,
		.related {
			ul {
				list-style: none;
				padding: 0;
				margin: 0;
			}

			ul ul {
				display: none;
			}

			li {
				padding: 0;
				margin: 0;
			}

			li + li {
				margin-top: 20px;
			}
		}

		.widget_nav_menu,
		.navigation {
			li span,
			li a {
				border-left: 7px solid transparent;
				line-height: 1.1em;
				padding-left: 10px;
				font-size: 1.1em;
				display: block;
			}

			li.current a,
			li.active a {
				border-left-color: $link-color;
				color: $text-color;
			}
		}

		@include breakpoint(tablet) {
			margin-top: 40px;
			float: none;
			width: 100%;
		}
	}

/*
 * ================================
 * 	_SectionBlog
 * ================================
 */

	#page-blog {
		border-top: 1px solid $brand-primary;
		overflow: hidden;
		padding: 80px 0;

		.link {
			margin: 40px 0 0 0;
			text-align: center;

			.btn {
				padding: 18px 80px;
				font-size: 1.2em;
			}
		}

		.blog-container {
			overflow: hidden;
			margin: 0 -20px;
		}

		.blog-container > h1 {
			text-transform: uppercase;
			margin-bottom: 30px;
			font-size: em(50px);
			text-align: center;
			font-weight: bold;

            span {
                font-weight: 300;
            }
		}

		.post {
			text-align: center;
			padding: 0 20px;
			float: left;
			width: 50%;

			@include breakpoint(mobile) {
				float: none;
				width: 100%;
			}

			a {
				text-decoration: none;
				color: $text-color;
				display: block;

				&:hover,
				&:focus {
					h1 {
						color: $link-hover-color;
					}
				}
			}

			h1 {
				font-size: 1em;
				padding: 0;
				margin: 0;
			}

			p {
				padding: 0;
				margin: 0;
			}

			.img-container {
				margin-bottom: 30px;
				text-align: center;
				overflow: hidden;
				height: 250px;
			}

			.heading {
				color: $brand-color-1;
				font-size: 1.3em;
			}

			.content {
				margin-top: 10px;
			}

			.meta {
				font-weight: 300;
				margin-top: 10px;

			 	p {
					display: inline-block;
				}
			}

			.date .icon {
				@include icon-font();
				@include icon($fa-var-clock-o);
			}

			.comments .icon {
				@include icon-font();
				@include icon($fa-var-comment-o);
			}
		}
	}

/*
 * ================================
 * 	_PostContent
 * ================================
 */

	.post-container.post-index.faq {
		&.accordion {
			.post .heading {
				color: $link-color;
				cursor: pointer;

				h1 {
					&:before {
						@include icon-font();
						padding-right: 10px;
						font-size: .85em;
						line-heght: 1em;
					}

					@include icon($fa-var-chevron-down);
				}

				&:hover,
				&:focus {
					text-decoration: underline;
				}
			}

			.post .content {
				transition: all .3s ease;
				visibility: hidden;
				padding-bottom: 0;
				padding-top: 0;
				opacity: 0;
				height: 0;
			}

			.post.active .heading {
				h1 {
					@include icon($fa-var-chevron-up);
				}
			}

			.post.active .content {
				visibility: visible;
				padding: 5px 30px;
				height: auto;
				opacity: 1;
			}
		}

		.post {
			background-color: #fff;
			overflow: hidden;

			.heading {
				border-radius: 2px 2px 0 0;
				border-color: #ddd;
				padding: 10px 0;
				color: #333;
				margin: 0;
			}

			.heading h1 {
				text-transform: none;
				font-weight: 400;
				font-size: 1.1em;
				padding: 0;
				margin: 0;
			}

			.content {
				padding: 5px 30px;
			}
		}

		.post + .post {
			margin-top: 10px;
		}
	}

/*
 * ================================
 * 	_Breadcrumbs
 * ================================
 */

 	#breadcrumbs {
 		position: relative;
 		overflow: hidden;
		font-size: .85em;
		padding: 20px 0 5px 0;

 	    @include breakpoint(tablet) {
 			display: none;
 	    }

 		&:after {
 			background-size: contain;
 			position: absolute;
 			height: 100%;
 			width: 100%;
 			content: "";
 			left: 0;
 			top: 0;

 		}

 		ul {
 			position: relative;
 			list-style: none;
			overflow: hidden;
 			z-index: 1;
			padding: 0;
 			margin: 0;
 		}

 		li {
			display: inline-block;
 			white-space: nowrap;
 			position: relative;
 			line-height: 30px;
 			padding: 0;
 			margin: 0;
 		}

 		li + li {
 			@include icon($fa-var-angle-right);

 			&:before {
 				position: relative;
 				padding: 0 8px;
 			}
 		}

		a.home {
			@include icon($fa-var-home);

			span {
				display: none;
			}

			&:hover,
			&:focus {
				color: $link-hover-color;
			}

			&:before {
				position: relative;
			}
		}
 	}


/*
 * ================================
 * 	_Bibbeo Difference
 * ================================
 */

	#bibbeo-difference {
        .container {
            padding: 20px 5px 0 5px;
            position: relative;
            max-width: 653px;
            overflow: hidden;
            margin: 0 auto;
        }

        .heading {
            position: absolute;
            color: #798897;
            left: 0;
            top: 0;

            @include breakpoint(tablet) {
                padding: 0 0 40px 0;
                text-align: center;
                position: relative;
            }
        }

        .heading p {
            font-size: 12px;
            padding: 0;
            margin: 0;

            @include breakpoint(tablet) {
                display: none;
            }
        }

        .heading {
			h1,h2,h3,h4,h5,h6 {
				font-family: $font-family-base;
				text-transform: uppercase;
				font-size: em(38px);
				margin: 0 0 15px 0;
				line-height: .95em;
				font-weight: 300;
				padding: 0;

				span {
					font-weight: bold;
					color: #242969;
				}
			}
        }

        .content {
            background: url("#{$image-path}/bg-bibbeo-difference.png") no-repeat 50% 50%;
            text-align: center;
            position: relative;
            max-width: 500px;
            height: 500px;
            float: right;
            width: 100%;

            @include breakpoint(tablet) {
                background: transparent;
                margin: 0 auto;
                height: auto;
                float: none;
            }
        }

        .content-box {
            transition: all .3s ease;
            position: absolute;
            text-align: center;
            overflow: hidden;
            height: 250px;
            right: 150px;
            width: 190px;
            top: 120px;

            &.out {
                visibility: hidden;;
                opacity: 0;
            }

            &.in {
                visibility: visible;
                opacity: 1;
            }

            @include breakpoint(tablet) {
                display: none;
            }
        }

        .bucket {
            position: absolute;
            cursor: pointer;
            height: 188px;
            width: 188px;

            .img-container {
                background-position: 50% 50%;
                background-repeat: no-repeat;
                position: absolute;
                height: 100%;
                width: 100%;
                left: 0;
                top: 0;
            }

            .bucket-content {
                display: none;
            }

            &:after {
                font-family: $font-family-secondary;
                background: #798897;
                position: absolute;
                border-radius: 50%;
                text-align: center;
                line-height: 25px;
                font-size: 14px;
                display: block;
                height: 25px;
                color: #fff;
                width: 25px;
                content: "";
            }
        }

        .bucket-step {
            background-repeat: no-repeat;
            background-position: 0 0;
            background-size: cover;
            position: absolute;
            display: block;
            height: 22px;
            width: 36px;
            content: "";
        }

        .bucket-1 {
            margin-left: -94px;
            top: -50px;
            left: 50%;

            &:after {
                content: "1";
                right: 20px;
                top: 30px;
            }
        }

        .bucket-2 {
            right: -5%;
            top: 96px;

            &:after {
                content: "2";
                right: 20px;
                top: 40px;
            }
        }

        .bucket-3 {
            right: 10%;
            bottom: 0;

            &:after {
                content: "3";
                right: 20px;
                top: 50%;
            }
        }

        .bucket-4 {
            bottom: 0;
            left: 9%;

            &:after {
                content: "4";
                left: 5px;
                top: 50%;
            }
        }

        .bucket-5 {
            top: 96px;
            left: 0;

            &:after {
                content: "5";
                left: 30px;
                top: 40px;
            }
        }

        .bucket-step-1 {
            background-image: url("#{$image-path}/bibbeo_info-graphic-step1b.svg");
            height: 38px;
            width: 42px;
            right: 23%;
            top: 80px;
        }

        .bucket-step-2 {
            background-image: url("#{$image-path}/bibbeo_info-graphic-step2b.svg");
            height: 38px;
            width: 34px;
            right: 12%;
            top: 300px;
        }

        .bucket-step-3 {
            background-image: url("#{$image-path}/bibbeo_info-graphic-step3b.svg");
            margin-right: -20px;
            height: 40px;
            bottom: 45px;
            width: 40px;
            right: 50%;
        }

        .bucket-step-4 {
            background-image: url("#{$image-path}/bibbeo_info-graphic-step4b.svg");
            height: 40px;
            width: 40px;
            top: 280px;
            left: 10%;
        }

        @include breakpoint(tablet) {
            .bucket-step {
                display: none;
            }

            .bucket,
            .bucket-1,
            .bucket-2,
            .bucket-3,
            .bucket-4,
            .bucket-5 {
                position: relative;
                overflow: hidden;
                bottom: auto;
                height: auto;
                clear: both;
                width: 100%;
                right: auto;
                left: auto;
                top: auto;
                margin: 0;

                .img-container {
                    position: relative;
                    height: 188px;
                    float: left;
                    width: 30%;
                }

                .bucket-content {
                    padding: 40px 0 40px 5%;
                    text-align: left;
                    display: block;
                    height: auto;
                    float: right;
                    width: 65%;
                }

                &:after {
                    bottom: auto;
                    right: auto;
                    left: 0;
                    top: 0;
                }

                & + .bucket {
                    margin-top: 40px;
                }
            }
        }

        @include breakpoint(mobile) {
            .bucket {
                .img-container {
                    background-position: 0 0;
                    height: 120px;
                    float: none;
                    width: 100%;
                }

                .bucket-content {
                    padding: 40px 0;
                    float: none;
                    width: 100%;
                }
            }
        }
	}

}

