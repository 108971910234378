
 	// Strip units
	@function strip-units($val) {
		@return $val / ($val * 0 + 1);
	}

	// Px to em conversion
	@function em($target, $context: $font-size-base) {
		@if not unitless($target) {
			$target: strip-units($target);
		}

		@if not unitless($context) {
			$context: strip-units($context);
		}

		@if $target == 0 { @return 0 }
		@return #{$target/$context}em
	}

