/**
 * Typography Styles
 */

	body {
		font-family: $font-family-base;
		font-size: em($font-size-base);
		color: $text-color;
		font-style: normal;
		line-height: 1em;
		font-weight: 400;
	}

	h1, h2, h3, h4, h5, h6 {
		font-weight: normal;
		font-style: normal;
	}

	h1 {
		font-family: $font-family-secondary;
		line-height: em($font-size-base);
		font-size: em(28px);
		padding: 0 0 20px 0;
	}

	h2 {
		font-family: $font-family-secondary;
		line-height: em($font-size-base);
		padding: 0 0 10px 0;
		font-size: em(21px);
	}

	h3 {
		font-family: $font-family-secondary;
		line-height: em($font-size-base);
		font-size: em(18px);
		margin: 0 0 8px 0;
	}

	h4 {
		border-bottom: 1px solid $text-color;
		font-size: em($font-size-base + 2px);
		padding: 0 0 em(5px) 0;
		margin: 0 0 em(8px) 0;
	}

	h5 {
		font-size: em($font-size-base + 2px);
		padding: 0 0 em(8px) 0;
		font-weight: bold;
	}

	h6 {
		font-size: em($font-size-base + 1px);
		padding: 0 0 em(8px) 0;
		font-weight: bold;
	}

	a {
		text-decoration: none;
		color: $link-color;

		@include transition(color, .1s);

		&:hover,
		&:focus {
			color: $link-hover-color;
			text-decoration: underline;
			outline: 0;
		}
	}

	p {
		line-height: 1.4;
		margin-bottom: em(25px);
	}

	ul {
		list-style: circle;
	}

	ol,
	ul {
		margin: 0 0 em(15px) em(35px);
		padding: 0;
		line-height: 1.2em;
	}

	li {
		padding: 0 0 em(5px) 0;

		ul,
		ol {
			padding: em(5px) 0 0 0;
			margin: 0 0 em(5px) em(25px);
		}
	}

	dl {
		margin: 0;
		padding: 0 0 em(20px) 0;

		dt {
			font-size: 1.2em;
			padding-bottom: 5px;
		}

		dd {
			padding-bottom: 15px;
			margin-left: em(30px);
			line-height: 1.2em;
		}
	}

	table {
		background-color: transparent;
		border-collapse: collapse;
		border-spacing: 0;
		max-width: 100%;
		border:1px solid #e8e4e3;
		margin-bottom: em(20px);
		width:100%;
		overflow:auto;

		th {
			border: 1px solid #e8e4e3;
			padding: em(10px) 1%;
			text-align: center;
			font-weight: bold;
			vertical-align: middle;
		}

		td {
			border: 1px solid #e8e4e3;
			padding: em(10px) 1%;
			text-align: left;
		}

		&.no-border {
			border: none;
			td,th {border:none;}
			th{text-align: left;text-decoration: underline;}
		}
	}

	hr {
		margin: em(20px) 0;
		border: 0;
		border-top: 1px solid #eee;
		border-bottom: 1px solid #2b2b2b;
	}

	address {
		display: block;
		margin-bottom: em(20px);
		font-style: normal;
		line-height: em(20px);
	}

	code,
	pre {
		font-family: $font-family-monospace;
		padding: 0 em(3px) em(2px);
		font-size: em(12px);
		color: #333333;
	}

	code {
		padding: em(2px) em(4px);
		background: #f0ddb8;
		margin: em(3px) 0;
		color: #545454;
	}

	pre {
		border: 1px solid rgba(0, 0, 0, 0.15);
		background-color: #f5f5f5;
		word-break: break-all;
		word-wrap: break-word;
		white-space: pre-wrap;
		line-height: em(20px);
		margin: 0 0 em(10px);
		font-size: em(13px);
		border-radius: 4px;
		padding: em(9px);
		display: block;
	}

	pre code {
		background-color: transparent;
		color: inherit;
		padding: 0;
		border: 0;
	}

	blockquote {
		padding: 5px 40px;
		margin: 0 0 15px 0;
		line-height: 1.4em;
		font-style: italic;
		font-size: 1.2em;

		p {
			margin: 0;
			padding: 0;
		}

		footer {
			padding: 15px 0 0 0;
			text-align: right;
		}
	}

	small {
		font-size: 80%;
	}

	strong {
		font-weight: bold;
	}

	em {
		font-style: italic;
	}

	cite {
		font-style: normal;
	}

	img {
		transition: opacity .3s ease;
		max-width: 100%;
	}

	iframe {
		max-width: 100%;
	}

	nav ul {
		list-style: none;
		padding: 0;
		margin: 0;
	}

	.frame-box {
		border: 1px solid $text-color;
		overflow: hidden;
		display: block;
	}

	.text-left {
		text-align: left;
	}

	.text-right {
		text-align: right;
	}

	.text-center {
		text-align: center;
	}

	.text-justify {
		text-align: justify;
	}

	.text-uppercase {
		text-transform: uppercase;
	}

	.small {
		font-size: 85%;
	}

	.hide {
		display: none;
	}

	.size-auto,
	.size-full,
	.size-large,
	.size-medium,
	.size-thumbnail {
		max-width: 100%;
		height: auto;
	}

	.screen-reader-text {
		text-indent: -999em;
		position: absolute;
		padding: 0;
		height: 0;
		margin: 0;
		width: 0;
		left: 0;
		top: 0;
	}

	.visible-mobile {
		display: none;

		@include breakpoint(tablet) {
			display: block;
		}
	}

	.hidden-mobile {
		display: block;

		@include breakpoint(tablet) {
			display: none;
		}
	}
