/**
 * Reset Styles
 */

	html,body,div,span,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,abbr,
	address,cite,code,del,dfn,em,img,ins,kbd,q,samp,small,strong,sub,sup,var,
	b,i,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,
	thead,tr,th,td,article,aside,canvas,details,figcaption,figure,footer,header,
	hgroup,menu,nav,section,summary,time,mark,audio,video {
		-webkit-font-smoothing:antialiased;
		vertical-align:baseline;
		font-size:100%;
		font:inherit;
		padding:0;
		margin:0;
		border:0;
	}

	article,aside,details,figcaption,figure,
	footer,header,hgroup,menu,nav,section {
		display:block;
	}

	nav ul,nav li{
		list-style:none;
	}

	form{
		margin:0;
	}

	button,input,select,textarea{
		vertical-align:baseline;
		*vertical-align:middle;
		font-size:100%;
		margin:0;
	}

	button,input{
		line-height:normal;
	}

	input[type="search"]{
		-webkit-box-sizing:content-box;
		-webkit-appearance:textfield;
		-moz-box-sizing:content-box;
		box-sizing:content-box;
	}

	input[type="checkbox"],input[type="radio"]{
		box-sizing:border-box;
	}

	input:invalid,textarea:invalid{
		background-color:#f0dddd;
	}

	textarea{
		vertical-align:top;
		overflow:auto;
	}

	input,select{
		vertical-align:middle;
	}

	fieldset{
		padding:0;
		border:0;
		margin:0;
	}

	button,
	input[type="button"],
	input[type="reset"],
	input[type="submit"] {
		-webkit-appearance: button;
		cursor: pointer;
	}

	button[disabled],
	input[disabled] {
		cursor: default;
	}

	input[type="checkbox"],
	input[type="radio"] {
		box-sizing: border-box;
		padding: 0;
	}

	input[type="search"] {
		-webkit-box-sizing: content-box;
		-webkit-appearance: textfield;
		-moz-box-sizing: content-box;
		box-sizing: content-box;
	}

	input[type="search"]::-webkit-search-decoration,
	input[type="search"]::-webkit-search-cancel-button {
		-webkit-appearance: none;
	}

	button::-moz-focus-inner,
	input::-moz-focus-inner {
		padding: 0;
		border: 0;
	}

	a,
	input[type="submit"],
	label,
	button,
	input[type="button"],
	input[type="reset"] {
		cursor: pointer;
	}

	legend {
		white-space: normal;
		padding: 0;
		border: 0;
	}

	blockquote,
	q {
		quotes: none;
		margin: 1em 40px;
	}

	blockquote:before,
	blockquote:after,
	q:before,
	q:after {
		content: none;
		content: "";
	}

	ins {
		background-color: #ff9;
		text-decoration: none;
		color: #000;
	}

	mark {
		font-style: italic;
		font-weight: bold;
		background: #ff0;
		color: #000;
	}

	pre {
		white-space: pre-wrap;
		word-wrap: break-word;
		white-space: pre;
	}

	sub,
	sup {
		position: relative;
		vertical-align: baseline;
		line-height: 0;
		font-size: 75%;
	}

	sup {
		top: -0.5em;
	}

	sub {
		bottom: -0.25em;
	}

	del {
		text-decoration: line-through;
	}

	abbr[title],
	dfn[title] {
		border-bottom: 1px dotted;
		cursor: help;
	}

	dfn {
		font-style: italic;
	}

	hr {
		border-top: 1px solid #ccc;
		display: block;
		margin: 1em 0;
		height: 1px;
		padding: 0;
		border: 0;
	}

	table {
		border-collapse: collapse;
		border-spacing: 0;
	}

	td {
		vertical-align: top;
	}

	hr {
		border-top: 1px solid #ccc;
		display: block;
		margin: 1em 0;
		height: 1px;
		padding: 0;
		border: 0;
	}

	em,
	i {
		font-style: italic;
	}

	strong,
	b {
		font-weight: bold;
	}

	small {
		font-size: 80%;
	}

	figure {
		margin: 0;
	}

	img {
		vertical-align: middle;
		border: 0;
	}

