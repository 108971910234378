/**
 * Footer Styles
 */

#page-footer {

/*
 * ================================
 * 	_Global
 * ================================
 */

    // background: $footer-color url("#{$image-path}/bg-footer.jpg") repeat 0 0;
    background: $footer-color;
	background-size: cover;
	position: relative;
	overflow: hidden;
	font-size: 100%;
    color: $text-color;
    // color: #fff;

	a {
        color: $text-color;
        // color: #fff;
	}

/*
 * ================================
 * 	_FooterWidgets
 * ================================
 */

 	#footer-widgets {
        background: $brand-color-1;
 		padding: 120px 0;
        color: #fff;

        a {
            color: #fff;
        }

		.widget-heading {
			font-size: 1.5em;
		}

		.widget + .widget {
			margin-top: 40px;
		}

		.column {
			float: left;
			width: 75%;

			& + .column {
				width: 25%;
			}
		}

        .widget {
            p,ul, dl {
                &:last-child {
                    padding: 0;
                    margin: 0;
                }
            }
        }

		.widget_nav_menu,
		.navigation {
			ul {
				list-style: none;
				padding: 0;
				margin: 0;
			}

			li {
				padding: 0;
				margin: 0;
			}

			a {
				display: block;
				padding: 5px 0;
			}
		}

		.logo {
			text-align: center;
		}

		h1,h2,h3,h4,h5,h6 {
            font-family: $font-family-secondary;
			font-weight: bold;
			font-size: 1.2em;
		}
	}

/*
 * ================================
 * 	_FooterBar
 * ================================
 */

	#footer-bar {
        color: lighten($text-color, 15%);
        padding: 70px 0 35px 0;
		overflow: hidden;

        @include breakpoint(tablet) {
            padding-top: 35px;
        }

        a {
            color: lighten($text-color, 15%);
        }

        .column {
            float: left;

            & + .column {
                float: right;
            }
        }

        .logo {
            max-width: 100px;
        }

		.row {
			overflow: hidden;
			float: right;
			clear: right;
			padding: 0;
			margin: 0;
		}

		.row + .row {
			padding-top: 10px;
		}

		.copyright {
            font-size: .8em;
			float: left;

			p {
				display: inline-block;
				line-height: 32px;
				float: left;
				padding: 0;
				margin: 0;
			}
		}

		.navigation {
			text-transform: uppercase;
			font-size: 1.2em;
			float: left;

			ul {
				display: inline-block;
				margin: 0 0 0 20px;
				overflow: hidden;
				list-style: none;
				float: left;
				padding: 0;

				@include breakpoint(tablet) {
					display: none;
				}
			}

			li {
				display: inline-block;
				line-height: 32px;
				padding: 0;
				margin: 0;
			}

			li + li {
				margin-left: 30px;
			}

            a {
                color: $link-color;
            }
		}

		.navigation-social {
			text-transform: uppercase;
			font-size: 1.2em;
			float: left;

			ul {
				display: inline-block;
				margin: 0 0 0 20px;
				overflow: hidden;
				list-style: none;
				float: left;
				padding: 0;
			}

			li {
				display: inline-block;
				line-height: 32px;
                font-size: 28px;
				padding: 0;
				margin: 0;
			}

			li + li {
				margin-left: 15px;
			}

            a {
                color: $link-color;

                &:hover {
                    color: $link-hover-color;
                }
            }
		}

		.navigation-footer {
			margin: 0 10px 0 0;
            font-size: .8em;
			float: left;

			ul {
				margin: 0 0 0 20px;
				overflow: hidden;
				list-style: none;
				display: inline-block;
				float: left;
				padding: 0;

				@include breakpoint(tablet) {
					display: none;
				}
			}

			li {
				display: inline-block;
				line-height: 32px;
				padding: 0;
				margin: 0;
			}

			li + li {
				margin-left: 5px;
			}

			li + li:before {
				content: "|";
				padding-right: 5px;
			}
		}

		.branding {
			float: right;
			overflow: hidden;

			a {
				background: url("#{$image-path}/logo-okaydesign.png") no-repeat top right;
				text-transform: lowercase;
				text-decoration: none;
				padding: 0 45px 0 0;
				white-space: nowrap;
				line-height: 33px;
				text-align: right;
				display: block;
				text-indent: 0;
				float: right;
				height: 33px;
				clear: both;
				width: auto;

				&:hover span {
					color: #d51b00;
				}

				@include breakpoint(tablet) {
					text-indent: -999em;
					line-height: 33px;
					text-align: left;
					height: 33px;
					padding: 0;
					width: 45px;
				}
			}

			span {
				text-decoration: underline;
				@include transition(color .3s ease);
			}
		}
	}

}
