/**
 * Widget Styles
 */

/*
 * ================================
 * 	_Global
 * ================================
 */

	.widget-wrap {
 		font-size: .8em;

 		.widget + .widget {
			overflow: hidden;
 			margin-top: 40px;
 		}

 		.posts-widget {
 			a:hover {
	 			text-decoration: none;
	 		}
 		}
 	}

/*
 * ================================
 * 	_PostsWidget
 * ================================
 */

	.widget.widget_recent_entries,
	.widget.posts-widget {
		.post-article + .post-article {
			border-top: 1px solid darken($brand-secondary,10%);
			padding-top: 25px;
			margin-top: 25px;
		}

		.post-article .post-content {
			padding: 0;
			margin: 0;
		}

		.post-article .post-info{
			margin: 0 0 10px 0;
		}

		.post-article .post-info h1{
			font-size: em(18px);
			margin: 0 0 5px 0;
			line-height: 1em;
			padding: 0;
		}

		.post-article .post-info .date{
			padding: 0;
			margin: 0;
		}

		.post-article a {
			display: block;

			&:hover {
				text-decoration: none;

				.post-content {
					text-decoration: underline;
				}
			}
		}
	}

/*
 * ================================
 * 	_NavigationWidget
 * ================================
 */

	.widget.widget_nav_menu,
	.widget.nav-widget {
		li {
			font-size: 1.1em;
			padding: 0;
			margin: 0;
		}

		li + li {
			padding-top: 15px;
		}
	}

/*
 * ================================
 * 	_CategoriesWidget
 * ================================
 */

	.widget.widget_categories {
		li {
			font-size: 1.1em;
			padding: 0;
			margin: 0;
		}

		li + li {
			padding-top: 15px;
		}
	}

/*
 * ================================
 * 	_ProductSearchWidget
 * ================================
 */

	.widget.widget_product_search {
		form {
			position: relative;
		}

		label {
			padding: 0;
			margin: 0;
		}

		input[type="submit"] {
			border-radius: 0 3px 3px 0;
			padding: 5px 15px;
			line-height: 20px;
			margin-top: 15px;
			font-size: 1em;

			@include breakpoint(tablet) {
				width: auto;
			}
		}
	}

/*
 * ================================
 * 	_SearchWidget
 * ================================
 */

	.widget.widget_search {
		form {
			position: relative;
		}

		.search-submit {
			position: relative;
			z-index: 1;
			right: 0;
			top: 0;

			input[type="submit"] {
				padding: 5px 15px;
				line-height: 20px;
				font-size: 1em;
			}
		}

		.search-label {
			padding: 0;
			margin: 0;
		}

		input[type="submit"] {
			border-radius: 0 3px 3px 0;

			@include breakpoint(tablet) {
				width: auto;
			}
		}
	}

