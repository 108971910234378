/**
 * Header Styles
 */

#page-header {

/*
 * ================================
 * 	_Global
 * ================================
 */

	background: $header-color;
	position: absolute;
	padding-top: 37px;
	font-size: 100%;
	z-index: 300;
	width: 100%;
	clear: both;

	@include breakpoint(tablet) {
		height: $header-height-mobile;
		padding-top: 0;
	}

	.header-top {
		border-bottom: 1px solid $brand-border;
		text-transform: uppercase;
		background: rgba($header-color, .9);
		position: fixed;
		font-size: .7em;
		padding: 5px 0;
		display: block;
		z-index: 305;
		width: 100%;
		left: 0;
		top: 0;

		@include breakpoint(tablet) {
			display:  none;
		}

		@include breakpoint(mobileHeight) {
			position: absolute;
		}

		a {
			color: lighten($text-color, 15%);
			font-weight: bold;
		}
	}

	.header-bottom {
		border-bottom: 1px solid $brand-border;
		height: $header-height;

		@include breakpoint(tablet) {
			height: $header-height-mobile;
		}
	}

/*
 * ================================
 * 	_Navigation
 * ================================
 */


 	.nav-button {
 		margin-top: -(($header-height-mobile / 2.5) / 2);
 		height: $header-height-mobile / 2.5;
 		text-decoration: none;
 		color: $brand-color-1;
 		position: absolute;
 	 	text-align: center;
 		cursor: pointer;
		display: none;
 		width: 30px;
 		top: 50%;
 		right: 0;

 	 	@include transition(all .3s ease);

 	 	@include breakpoint(tablet) {
			display: block;
 		}

 		&:hover,
 	 	&:focus {
 		 	color: $link-hover-color;
 	 	}

 		&:before,
 		&:after {
 			transition: all .3s ease-in-out;
 			background: $link-color;
 			position: absolute;
 			display: block;
 			content: "";
 			width: 100%;
 			height: 5px;
 			left: 0;
 		}

 		&:before {
 			top: 0;
 		}

 		&:after {
 			bottom: 0;
 		}

 		span {
 			transition: all .3s ease-in-out;
 			background: $link-color;
 			position: absolute;
 			display: block;
 			margin: auto;
 			width: 100%;
 			height: 5px;
 			bottom: 0;
 			right: 0;
 			top: 0;
 		}

 	 	body.nav-open & {
 			span,
 			&:before,
 			&:after {
 		 		background-color: $link-hover-color;
 			}

 			span {
 				right: -50px;
 				opacity: 0;
 			}

 			&:before {
 				transform: rotate(-45deg);
 				top: 40%;
 			}

 			&:after {
 				transform: rotate(45deg);
 				bottom: 40%;
 			}
 	 	}
  	}

	.navigation {
		padding: 0;
		margin: 0;

		@include clearfix();

		ul {
			list-style: none;
			padding: 0;
			margin: 0;
		}

		> ul > li > ul {
			box-shadow: -2px 2px 3px -1px rgba(0,0,0,.5);
			background: darken($background-color, 5%);
			transition: all .3s ease;
			margin: -10px 0 0 20px;
			text-transform: none;
			position: absolute;
			visibility: hidden;
			min-width: 200px;
			font-size: .8em;
			padding: 10px 0;
			opacity: 0;
			top: 100%;
			left: 0;

			.dropdown-menu {
				box-shadow: 0 3px 3px -2px rgba(0,0,0,.5);
				background: darken($background-color, 5%);
				transition: all .3s ease;
				visibility: hidden;
				position: absolute;
				min-width: 100px;
				padding: 10px 0;
				opacity: 1;
				opacity: 0;
				left: 100%;
				top: 0;
			}

			&.edge .dropdown-menu {
				right: 100%;
				left: auto;
			}

			.dropdown-menu .dropdown-menu {
				background: transparent;
				visibility: visible;
				position: relative;
				box-shadow: none;
				text-indent: 10%;
				max-width: 200px;
				min-width: auto;
				display: block;
				opacity: 1;
				padding: 0;
				left: 0;
				top: 0;
			}

			&.edge .dropdown-menu .dropdown-menu {
				right: auto;
				left: auto;
			}

			a {
				text-decoration: none;
				white-space: nowrap;
				color: $text-color;
				padding: 8px 10px;
				display: block;
			}

			a:hover,
			a:focus {
				color: $text-color;
				background: #eee;
			}

			&:after,
			&:before {
				border: solid transparent;
				pointer-events: none;
				position: absolute;
				bottom: 100%;
				content: " ";
				left: 50px;
				height: 0;
				width: 0;
			}

			&:after {
				border-color: rgba(136, 183, 213, 0);
				border-bottom-color: darken($background-color, 5%);
				border-width: 10px;
				margin-left: -10px;
			}

			&:before {
				border-color: rgba(194, 225, 245, 0);
				border-bottom-color: darken($background-color, 5%);
				border-width: 12px;
				margin-left: -12px;
			}
		}

		> ul > li {
			position: relative;
			float: left;
			padding: 0;
			margin: 0;
		}

		> ul > li > a {
			line-height: 1em;
			display: block;

			@include transition(background .3s ease);
		}

		> ul li {
			&.edge .dropdown-menu .dropdown-menu {
				right: 100%;
				left: auto;
			}

			&.active > a {
				background: $background-color;
				color: $text-color;
			}

			&.active > ul {
				visibility: visible;
				display: block;
				opacity: 1;
			}
		}
	}

	.header-top .navigation {
		float: left;

		> ul > li + li {
			margin-left: 25px;
		}

		> ul > li > a {
			line-height: 16px;
			padding: 5px 0;
		}
	}

	.header-bottom .navigation {
		text-transform: uppercase;
		font-weight: bold;
		font-size: .9em;
		float: right;

		> ul {
			display: block;
			float: right;
		}

		> ul > li {
			&.active > a,
			&.current > a,
			> a:hover,
			> a:focus {
				background: #eee;
				color: $text-color;
			}
		}

		> ul > li > a {
			margin: ($header-height / 2) - 21px 20px;
			text-decoration: none;
			color: $brand-color-1;
			border-radius: 5px;
			text-align: center;
			line-height: 22px;
			padding: 10px 5px;
			@include transition(background .3s ease);
		}

		@include breakpoint(900px) {
			font-size: .9em;
		}

		@include breakpoint(tablet) {
			> ul {
				display: none;
			}
		}
	}

	.navigation-actions {
		float: right;

		> div {
			display: inline-block;

			& + div {
				margin-left: 10px;
			}
		}

		a {
			line-height: 16px;
			padding: 5px 0;
			display: block;
		}

		.search {
			.icon {
				@include icon-font();
				@include icon($fa-var-search);
			}
		}

		.cart {
			.icon {
				@include icon-font();
				@include icon($fa-var-shopping-cart);
			}
		}

		.contact {
			.icon {
				@include icon-font();
				@include icon($fa-var-phone);
			}
		}
	}

/*
 * ================================
 * 	_Logo
 * ================================
 */

	.logo {
		line-height: 1em;
		padding: 20px 0;
		font-size: 1em;
		float: left;

		@include breakpoint(tablet) {
			padding: 10px 0;
		}

		a {
			height: $header-height - 40px;
			text-decoration: none;
			display: block;
			padding: 0;

			@include breakpoint(tablet) {
				height: $header-height-mobile - 20px;
			}
		}

		h1 {
			padding: 0;
		}

		h1,h2,h3,h4,h5,h6 {
			font-size: 1em;
			padding: 0;
			margin: 0;
		}

		img {
			max-width: none;
			height: 100%;
			width: auto;
		}
	}

}
